<template>
  <div style="min-height: 400px">
    <p v-if="!this.receiptStatus">
      {{ $t('hat.broadcast.detailTip') }}
    </p>
    <a-table
      :columns="columns"
      style="height: 450px"
      :scroll="{ y: 300 }"
      :pagination="false"
      :loading="loading"
      :dataSource="tableList"
    >
      <template slot="content" slot-scope="text, record">
        <audio-play :url="record.filePath" v-if="record.filePath" />
        <span v-else>--</span>
      </template>
    </a-table>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import { getDetailList } from '@/services/smart-hat/broadcast';
import { UploadService } from '@triascloud/services';
import Preview from '@/views/hat/file/preview/index.vue';
import AudioPlay from './audio-play.vue';

@Component({
  components: {
    LayoutContent,
    AudioPlay,
  },
})
export default class BroadcastDetail extends Vue {
  @Prop({ type: String, default: '' }) id;
  @Prop({ type: Boolean, default: true }) receiptStatus;
  @Prop({ type: Boolean, default: true }) replyStatus;

  mounted() {
    this.getTableList();
  }
  loading = false;
  tableList = [];
  async getTableList() {
    this.loading = true;
    try {
      const data = {
        input: this.id,
        size: 500,
        current: 1,
      };
      this.tableList = (await getDetailList(data)).records;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  get columns() {
    let column = [
      {
        title: this.$t('hat.broadcast.sendDevice'),
        dataIndex: 'deviceName',
        ellipsis: true,
        width: 250,
        customRender: (text, record) =>
          record.deviceUserName
            ? `${text}(${record.deviceUserName})`
            : `${text}`,
      },
      {
        title: this.$t('hat.broadcast.user'),
        dataIndex: 'deviceUserName',
      },
      {
        title: this.$t('hat.broadcast.playbackStatus'),
        dataIndex: 'broadcastReceiveStatus',
        customRender: text => this.playStatus[text],
      },
      {
        title: this.receiptStatus
          ? this.$t('hat.broadcast.listeningTime')
          : this.$t('hat.broadcast.estimatedListening'),
        width: 180,
        dataIndex: this.receiptStatus ? 'listeningTime' : 'estimatedTime',
        customRender: text =>
          text ? this.$moment(text).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        title: '回复',
        dataIndex: 'filePath',
        scopedSlots: { customRender: 'content' },
      },
    ];
    if (!this.receiptStatus) {
      delete column[2];
    }
    if (!this.replyStatus) {
      delete column[column.length - 1];
    }
    return column;
  }
  playStatus = {
    PLAYED: '已读',
    NOT_PLAYED: '未读',
    NOT_RECEIVED: '未读',
  };

  async loadOssPath(filePath) {
    // 判断是否是完整的文件路径
    const customService = new UploadService('/oss/iot/oss');
    return await customService.getAuth(filePath);
  }
  async openAudio(path) {
    console.log(path);
    Preview.createModal({
      urls: path,
      ossBasePath: '/oss/iot/oss',
    });
  }
}
</script>
<style lang="less" module>
.iconBox {
  cursor: pointer;
  .icon {
    font-size: 32px;
    transform: translateY(6px);
  }
}
</style>
