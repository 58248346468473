<template>
  <div class="audio-play">
    <div class="progress">
      <a-progress
        type="circle"
        :percent="percent"
        :width="30"
        :strokeWidth="12"
        :showInfo="false"
      />
    </div>
    <div class="operate-icon">
      <a-icon type="caret-right" v-if="!isPlaying" @click="playAudio" />
      <span @click="onPlayOrStop" v-else>
        <a-icon type="caret-right" v-if="isStop" />
        <a-icon type="pause" v-else />
      </span>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { UploadService } from '@triascloud/services';
import BenzAMRRecorder from 'benz-amr-recorder';

@Component()
export default class AudioPlay extends Vue {
  @Prop({ type: String, required: true }) url;

  mounted() {
    this.getAbsolutePath();
  }

  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  }

  absolutePath = '';
  async getAbsolutePath() {
    try {
      const customService = new UploadService('/oss/iot/oss');
      this.absolutePath = await customService.getAuth(this.url);
    } catch {
      return false;
    }
  }

  amr = null;
  isPlaying = false;
  duration = 0;
  timer = null;
  time = 0;
  percent = 0;
  playAudio() {
    this.time = 0;
    this.amr = new BenzAMRRecorder();
    this.amr.initWithUrl(this.absolutePath).then(() => {
      this.duration = this.amr.getDuration() * 1000;
      this.timer && clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.time = this.time + 100;
        this.percent = parseInt((this.time / this.duration) * 100);
      }, 100);
      this.isPlaying = true;
      this.amr.play();
    });
    this.amr.onEnded(() => {
      this.percent = 100;
      this.timer && clearInterval(this.timer);
      this.timer = null;
      this.isPlaying = false;
      this.isStop = false;
    });
  }

  /**
   * 暂停/继续
   */
  isStop = false;
  onPlayOrStop() {
    this.isStop = !this.isStop;
    if (this.isStop) {
      this.amr.pause();
      this.timer && clearInterval(this.timer);
      this.timer = null;
    } else {
      this.timer = setInterval(() => {
        this.time = this.time + 100;
        this.percent = parseInt((this.time / this.duration) * 100);
      }, 100);
      this.amr.resume();
    }
    // this.isStop ? this.amr.pause() : this.amr.resume();
    // this.amr.pauseOrResume();
  }
}
</script>
<style lang="less" scoped>
.audio-play {
  width: 30px;
  height: 28px;
  color: var(--font);
  position: relative;
  .progress {
    top: 0;
    right: 0;
    position: absolute;
  }
  .operate-icon {
    width: 30px;
    top: 0;
    right: 0;
    text-align: center;
    font-size: 16px;
    position: absolute;
    z-index: 100;
    cursor: pointer;
  }
}
</style>
